<template>
  <div v-if="!isHelpOpen" class="unconfirmed">
    <TopButton
      :text="t('common.help')"
      icon-name="info-question-circle"
      is-secondary
      @click="isHelpOpen = true"
    />

    <AppIcon name="payme-unconfirmed" size="60px" is-img-tag />
    <AppText
      variant="div"
      size="21px"
      mt="20px"
      class="font-medium"
    >
      {{ t('status.UNCONFIRMED') }}
    </AppText>
    <AppText
      variant="div"
      opacity="0.5"
      mt="10px"
      class="description"
    >
      <template v-if="confirmations.length > 1">
        {{ t('payment.unconfirmed.multipleTransactionsSubtitle') }}
      </template>
      <template v-else>
        {{ t('payment.unconfirmed.subtitle') }}
      </template>
    </AppText>
    <div
      v-for="(confirmation, index) in confirmations"
      :key="index"
      :class="{ conf__multi: confirmations.length > 1 }"
      class="conf"
    >
      <AppText
        v-if="confirmations.length > 1"
        opacity="0.5"
      >
        #{{ index + 1 }}
      </AppText>
      <div class="conf__item">
        <AppText :key="confirmation" :size="fontSize" class="font-medium">
          {{ confirmation }}
        </AppText>
        <AppText :size="fontSize" opacity="0.4" class="font-medium">
          /
        </AppText>
        <AppText :size="fontSize" opacity="0.4" class="font-medium">
          {{ maxConfirmations }}
        </AppText>
      </div>
      <AppText>
        {{ t('payment.unconfirmed.confirmations') }}
      </AppText>
    </div>
  </div>

  <HelpText v-else step="progress" @close-help="isHelpOpen = false" />
</template>

<script setup>
import { computed, ref } from 'vue';

import HelpText from '../../../HelpText.vue';

import TopButton from './TopButton.vue';

const props = defineProps({
  socketData: {
    type: Object,
    default: () => {},
  },
  maxConfirmations: {
    type: Number,
    default: 3,
  },
});

const isHelpOpen = ref(false);

const confirmations = computed(() => props.socketData.paymentsConfirmations);
const blockWidth = computed(() => (props.maxConfirmations > 9 ? '50px' : '38px'));

const fontSize = computed(() => (props.maxConfirmations > 1 ? '13px' : '18px'));
</script>

<style lang="scss" scoped>
.unconfirmed {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 95px 40px 95px;
  position: relative;

  :deep(.top__button) {
    position: absolute;
    top: 19px;
    left: 18px;
  }

  .description {
    text-align: center;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--color-black-007);
  }

  .conf {
    width: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .conf__item {
      width: v-bind('blockWidth');
      height: 28px;
      background: var(--color-EBEBEB);
      border-radius: 26px;
      margin-bottom: 8px;
      display: grid;
      grid-template-columns: auto 7px auto;
      align-items: center;

      &>:first-child {
        text-align: right;
      }

      &>:nth-child(2) {
        text-align: center;
      }
    }
  }

  .conf__multi {
    display: flex;
    flex-direction: row;

    .conf__item {
      margin: 0 8px 0 22px;
    }

    &:last-child {
      margin-top: 20px;
    }
  }
}
</style>
