<template>
  <div class="top__button" :class="{ secondary: isSecondary }">
    <AppIcon :name="iconName" size="18px" />
    <AppText :line-height="1.35">
      {{ text }}
    </AppText>
  </div>
</template>

<script>
export default {
  name: 'TopButton',
  props: {
    text: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.top__button {
  min-width: 74px;
  height: 36px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  @include transition-base(color);

  &.secondary {
    :deep(.text) {
      color: var(--color-black-05);
    }

    :deep(.icon) {
      path, rect {
        stroke: var(--color-black-05);
      }
    }
  }

  :deep(.icon) {
    margin-right: 10px;
    @include transition-base(all);
    path, rect {
      @include transition-base(stroke);
    }
  }

  &:hover {
    color: var(--color-primary);
    @include transition-base(color);

    :deep(.icon) {
      path {
        stroke: var(--color-primary);
        @include transition-base(stroke);
      }

      rect {
        stroke: var(--color-primary);
        @include transition-base(stroke);
      }
    }

    :deep(.text) {
      color: var(--color-primary);
    }
  }
}
</style>
