<template>
  <div v-if="!isHelpOpen" class="exp">
    <TopButton
      :text="t('common.help')"
      is-secondary
      icon-name="info-question-circle"
      @click="isHelpOpen = true"
    />

    <AppIcon
      :key="payment.mainIcon"
      class="icon"
      :name="payment.mainIcon"
      size="60px"
      is-img-tag
    />
    <AppText size="21px" class="font-medium title">
      {{ payment.title }}
    </AppText>
    <div class="description-wrapper">
      <AppText
        :class="[payment.isUnderpaid ? 'underpaid' : 'grey']"
        class="description"
        size="14px"
        :line-height="1.5"
      >
        {{ payment.text }}
      </AppText>
      <AppText
        v-if="canPaymentBeReactivated"
        variant="div"
        mt="40px"
        size="13px"
        color="var(--color-primary)"
        class="try-link"
        @click="onReactivatePayment"
      >
        {{ t('payment.expired.tryAgain') }}
      </AppText>
    </div>
  </div>

  <HelpText v-else step="status" @close-help="isHelpOpen = false" />
</template>

<script>
import { computed, ref } from 'vue';

import { i18n } from '@/plugins/localization';
import { getCurrency } from '@/utils/functions';

import HelpText from '../../../HelpText.vue';

import TopButton from './TopButton.vue';

export default {
  name: 'ExpiredCard',
  components: { HelpText, TopButton },
  props: {
    paymentData: {
      type: Object,
      required: true,
    },
    paymentStatus: {
      type: String,
      required: true,
    },
  },
  emits: ['reactivate'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const isHelpOpen = ref(false);

    const payment = computed(() => {
      const result = {
        text: '',
        title: t('payment.expired.title'),
        mainIcon: 'clock-icon',
        isUnderpaid: false,
      };

      const exchangeCurrency = getCurrency(props.paymentData?.exchangeCurrency);

      if (props.paymentData.state === 'EXPIRED_WHILE_AWAITING' && props.paymentStatus !== 'CANCELLED') {
        result.text = t('payment.expired.noTransactions');
      } else if (props.paymentData.state === 'EXPIRED_WHILE_CONFIRMING') {
        result.text = t('payment.expired.EXPIRED_WHILE_CONFIRMING');
      } else if (props.paymentData.state === 'EXPIRED_WHILE_UNDERPAID') {
        result.text = t('payment.expired.underpaidBy', { amount: props.paymentData.missingAmount, currency: exchangeCurrency });
        result.isUnderpaid = true;
      } else if (props.paymentData.state === 'UNDERPAID_EXPIRED_WITH_TWO_TX') {
        result.text = t('payment.expired.UNDERPAID_EXPIRED_WITH_TWO_TX');
      } else if (props.paymentStatus === 'UNAVAILABLE' || props.paymentStatus === 'CANCELLED') {
        result.title = t('payment.expired.unavailableTitle');
        result.mainIcon = 'warning-alert-circle';
        result.text = t('payment.expired.unavailableDescription');
      }

      return result;
    });

    const onReactivatePayment = async () => {
      emit('reactivate');
    };

    const canPaymentBeReactivated = computed(() => {
      const isExpired = props.paymentStatus === 'EXPIRED';
      const isNotPublic = props.paymentData?.type !== 'PUBLIC_PAYMENT';
      const availableReactivatedCount = props.paymentData?.reactivated < 2;
      const isNoTransactions = props.paymentData?.transactions?.length === 0;
      const isExpiredPermanent = props.paymentData.isExpiredPermanent;

      return isExpired && isNotPublic && availableReactivatedCount && isNoTransactions && !isExpiredPermanent;
    });

    return {
      isHelpOpen,
      payment,

      onReactivatePayment,
      canPaymentBeReactivated,
    };
  },
};
</script>

<style lang="scss" scoped>
.exp {
  height: 448px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  :deep(.top__button) {
    position: absolute;
    top: 19px;
    left: 18px;
  }

  & > .icon {
    margin-top: 138px;

    @include mq('mobile') {
      margin-top: 80px;
    }
  }

  & > .title {
    margin-top: 20px;
  }

  .description-wrapper {
    max-width: 270px;
    margin: 16px 0 90px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .description {
    min-height: 64px;
    text-align: center;

    &.underpaid {
      color: var(--color-error);
    }

    &.grey {
      color: var(--color-black-04);
    }
  }
}

.try-link {
  opacity: 1;
  @include transition-base(opacity);
  @include font-medium;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
</style>
