<template>
  <div style="padding: 0 80px; height: 448px; flex-direction: column;" class="flex-center">
    <AppIcon name="success-paid" size="60px" is-img-tag style="margin-bottom: 20px;" />
    <AppText size="21px" class="font-medium title">
      {{ t('payment.manualComplete.title') }}
    </AppText>
    <div style="margin-bottom: 16px;" />
    <AppText size="14px" :line-height="1.5" color="var(--color-black-04)" align="center">
      {{ t('payment.manualComplete.description') }}
    </AppText>
  </div>
</template>
