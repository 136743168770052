<template>
  <div
    class="clock"
    :class="{
      'no-data': timeLeft === '',
      'is-expired': isExpired,
    }"
  >
    <AppText variant="div">
      {{ timeLeft }}
    </AppText>
    <div class="line" />
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'PaymentTimer',
  props: {
    activatedAt: {
      type: String,
      default: '',
    },
    paymentStatus: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
  },
  emits: ['times-up'],
  setup(props, { emit }) {
    const MINUTES_TO_PAY = 10;
    const lineLength = ref('100%');

    const activatedTime = computed(() => dayjs(props?.activatedAt));
    const maxPaymentPeriod = computed(() => activatedTime.value.add(MINUTES_TO_PAY, 'minute'));

    const timeLeft = ref('');
    const isExpired = ref(false);

    const formatLenght = (num) => (String(num).length === 1 ? `0${String(num)}` : num);

    const getTimeLeft = () => {
      const now = dayjs();
      const allSeconds = maxPaymentPeriod.value.diff(now, 'second');

      lineLength.value = `${(allSeconds / (MINUTES_TO_PAY * 60)) * 100}%`;

      if (allSeconds === 0 || allSeconds < 0) {
        clearInterval(timer);
        timeLeft.value = '00:00';
        isExpired.value = true;
        emit('times-up');
        return;
      }

      const minutes = Math.floor((allSeconds) / 60);
      const seconds = allSeconds - (minutes * 60);
      timeLeft.value = `${formatLenght(minutes)}:${formatLenght(seconds)}`;
    };

    let timer = null;

    const resetComponentState = () => {
      clearInterval(timer);
      isExpired.value = false;
      timeLeft.value = '';
    };

    watch(computed(() => props.paymentStatus), (value) => {
      if (value === 'WAITING' || props.state === 'CONFIRMING_PAYMENT_WHILE_UNDERPAID' || props.state === 'UNDERPAID') {
        getTimeLeft();
        timer = setInterval(() => {
          getTimeLeft();
        }, 1000);
      }
      if (value === 'UNCONFIRMED' || value === 'EXPIRED') {
        resetComponentState();
      }
    }, { immediate: true });

    watch(computed(() => props.activatedAt), () => {
      isExpired.value = false;
      timeLeft.value = '';
    });

    return {
      timeLeft,
      lineLength,
      isExpired,
    };
  },
});
</script>

<style scoped lang="scss">
.clock{
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid var(--color-black-01);
  color: var(--color-primary);
  text-align: right;
  position: relative;

  :deep(.text) {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }

  .line {
    position: absolute;
    width: v-bind(lineLength);
    height: 2px;
    background: var(--color-primary);
    bottom: -2px;
    left: 0;
  }
}

.no-data {
  visibility: hidden;
}

.is-expired {
  color: var(--color-A70000);
}
</style>
