import { useRoute } from '@/router';
import { decryptString } from '@/utils/crypto';

export const useErrorProcessing = () => {
  const route = useRoute();
  const data = {};

  const IS_API_PAYMENT_ERROR = route.query?.code;
  if (IS_API_PAYMENT_ERROR) {
    try {
      const encodedString = decryptString(route.query?.code);
      const parsedData = JSON.parse(encodedString);

      data.status = parsedData.code;
      data.errorCode = `${parsedData.errorCode}.${parsedData.errorUid}`;
      data.failureDate = parsedData.date;
    } catch (error) {
      console.error(error);
    }
    window.history.pushState({}, null, '/payment/error');
  }

  return { data };
};
