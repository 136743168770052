<template>
  <div>
    <Teleport v-if="paymentStatus === 'UNCONFIRMED'" to="#payment-header">
      <AppSpinner
        v-if="paymentStatus === 'UNCONFIRMED'"
        :title="t('payment.label.awaitingConfirmation')"
      />
    </Teleport>

    <ManualCompleteCard v-if="paymentStatus === 'COMPLETE' && paymentData.state === 'COMPLETE_MANUAL'" />

    <CompleteCard
      v-else-if="paymentStatus === 'COMPLETE'"
      :payment-data="paymentData"
      :selected-coin="selectedCoin"
      :exchange-currency="exchangeCurrency"
    />

    <UnconfirmedCard
      v-else-if="paymentStatus === 'UNCONFIRMED' || paymentStatus === 'CONFIRMING'"
      :socket-data="socketData"
      :max-confirmations="maxConfirmations"
    />

    <ErrorCard
      v-else-if="['UNCONFIRMED', 'FAILED', 'ERROR', 'API_PAYMENT_ERROR'].includes(paymentStatus)"
      :payment-data="paymentData"
      :payment-status="paymentStatus"
    />

    <ExpiredCard
      v-else-if="['EXPIRED', 'UNAVAILABLE', 'CANCELLED'].includes(paymentStatus)"
      :payment-data="paymentData"
      :payment-status="paymentStatus"
      @reactivate="$emit('reactivate')"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import AppSpinner from '../AppSpinner.vue';

import CompleteCard from './StatusCards/CompleteCard.vue';
import ErrorCard from './StatusCards/ErrorCard.vue';
import ExpiredCard from './StatusCards/ExpiredCard.vue';
import ManualCompleteCard from './StatusCards/ManualCompleteCard.vue';
import UnconfirmedCard from './StatusCards/UnconfirmedCard.vue';

const props = defineProps({
  paymentData: {
    type: Object,
    default: () => {},
  },
  paymentStatus: {
    type: String,
    default: '',
  },
  maxConfirmations: {
    type: Number,
    default: 3,
  },
  socketData: {
    type: Object,
    default: () => {},
  },
  selectedCoin: {
    type: Object,
    default: () => {},
  },
  exchangeCurrency: {
    type: String,
    default: '',
  },
});
defineEmits('reactivate');

const isLoaded = ref(false);

if (props.paymentStatus) {
  isLoaded.value = true;
}

watch(() => props.paymentStatus, () => {
  isLoaded.value = true;
});
</script>
