<template>
  <div v-if="!isHelpOpen" class="error">
    <TopButton
      :text="t('common.help')"
      icon-name="info-question-circle"
      is-secondary
      @click="isHelpOpen = true"
    />

    <div class="header">
      <AppIcon
        :name="paymentStatus === 'API_PAYMENT_ERROR' ? 'warning-circle' : 'error-circle'"
        size="48px"
        is-img-tag
      />

      <AppText
        variant="div"
        size="16px"
        mt="20px"
        :line-htight="1.3"
        class="font-medium"
        style="text-align: center;"
      >
        <template v-if="paymentStatus === 'API_PAYMENT_ERROR'">
          {{ t('payment.externalError.subtitle') }}
        </template>
        <template v-else>
          {{ t('payment.error.subtitle') }}
        </template>
      </AppText>
    </div>

    <div class="payment-data">
      <div v-if="paymentStatus === 'API_PAYMENT_ERROR'" class="payment-data-item">
        <AppText color="var(--color-black-05)" style="margin-bottom: 4px;">
          {{ t('payment.error.errorCode') }}
        </AppText>
        <TooltipCopy is-icon-hidden :text="paymentData.errorCode">
          <AppText>
            {{ paymentData.errorCode }}
          </AppText>
        </TooltipCopy>
      </div>
      <div v-else class="payment-data-item">
        <AppText color="var(--color-black-05)" style="margin-bottom: 4px;">
          {{ t('payment.error.paymentID') }}
        </AppText>
        <TooltipCopy is-icon-hidden :text="paymentData.uuid">
          <AppText>
            {{ paymentData.uuid }}
          </AppText>
        </TooltipCopy>
      </div>

      <div class="payment-data-item">
        <AppText color="var(--color-black-05)" style="margin-bottom: 4px;">
          {{ t('payment.error.date') }}
        </AppText>

        <div>
          <DateFormatter :date="paymentData.failureDate" format="D MMM YYYY (h:mm:ss" is-utc /> <AppText v-if="paymentData.failureDate">
            UTC)
          </AppText>
        </div>
      </div>
    </div>
  </div>

  <HelpText
    v-else
    step="progress"
    @close-help="isHelpOpen = false"
  />
</template>

<script>
import { ref } from 'vue';

import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import HelpText from '@/views/client/HelpText.vue';
import TopButton from '@/views/client/ClientPayment/PaymentStatus/StatusCards/TopButton.vue';

export default {
  name: 'ErrorCard',
  components: {
    DateFormatter,
    HelpText,
    TopButton,
    TooltipCopy,
  },
  props: {
    paymentData: {
      type: Object,
      required: true,
    },
    paymentStatus: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isHelpOpen = ref(false);

    return { isHelpOpen };
  },
};
</script>

<style lang="scss" scoped>
.error {
  :deep(.top__button) {
    position: absolute;
    top: 19px;
    left: 18px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;

    :first-child {
      margin-top: 60px;
    }
  }

  .payment-data {
    margin: 40px;
    min-height: 100px;
    padding: 6px 20px;
    border-radius: 12px;
    background-color: var(--color-F7F7F7);
    display: flex;
    flex-direction: column;
  }

  .payment-data-item {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    &:first-child {
      border-bottom: 1px solid var(--color-E6E6E6);
    }
  }
}
</style>
