<template>
  <div class="info">
    <Teleport to="#payment-overlay">
      <div class="background-overlay" />
    </Teleport>
    <div class="header" @click="emit('close-help')">
      <AppIcon name="x-close" size="18px" />
    </div>

    <AppText
      variant="div"
      :opacity="0.4"
      mt="40px"
      class="font-medium"
    >
      {{ t('payment.help.conversionRates.title') }}
    </AppText>

    <AppText
      variant="div"
      mt="6px"
    >
      {{ t('payment.help.conversionRates.description') }}
    </AppText>

    <AppText
      variant="div"
      :opacity="0.4"
      mt="24px"
      class="font-medium"
    >
      {{ t('payment.help.paymentWindows.title') }}
    </AppText>

    <AppText
      variant="div"
      mt="6px"
    >
      {{ t('payment.help.paymentWindows.description') }}
    </AppText>

    <AppText
      variant="div"
      :opacity="0.4"
      mt="24px"
      class="font-medium"
    >
      {{ t('payment.help.underpayments.title') }}
    </AppText>

    <AppText
      variant="div"
      mt="6px"
    >
      {{ t('payment.help.underpayments.description') }}
    </AppText>

    <AppText
      variant="div"
      color="var(--color-error)"
      mt="24px"
      class="font-medium"
    >
      <template v-if="['status', 'progress'].includes(step)">
        {{ t('payment.help.alert') }}
      </template>
    </AppText>

    <a :href="link" target="_blank" rel="noopener noreferrer" class="learn__more">
      <AppText>
        {{ t('common.learnMore') }}
      </AppText>
      &nbsp;
      <AppIcon name="external-link" size="12px" />
    </a>
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount } from 'vue';

import { emitter } from '@/composables/useBus';

export default {
  name: 'HelpText',
  props: {
    step: {
      type: String,
      required: true,
    },
  },
  emits: ['close-help'],
  setup(props, { emit }) {
    onBeforeMount(() => {
      emitter.emit('help-opened', true);
    });

    onBeforeUnmount(() => {
      emitter.emit('help-opened', false);
    });

    const link = `https://${process.env.VUE_APP_DOCS_URL}/home/fundamentals/limitations`;

    return {
      emit,
      link,
    };
  },
};
</script>

<style lang="scss" scoped>
.info {
  position: relative;
  z-index: 999;
  padding: 20px 40px 0;
  border-radius: 20px;
  background-color: var(--color-white);

  @include mq('mobile') {
    padding: 1px 20px 0;
  }

  .header {
    position: absolute;
    width: 80px;
    height: 32px;
    top: 13px;
    left: 12px;
    padding-left: 26px;
    display: flex;
    align-items: center;
    cursor: pointer;

    :deep(.icon path) {
      fill: var(--color-black-04);
      @include transition-base('fill');
    }

    &:hover {
      :deep(.icon path) {
        fill: var(--color-primary);
      }
    }

    @include mq('mobile') {
      background: var(--color-white);
      width: 36px;
      height: 36px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0px 2px 2px var(--color-black-01);
      top: -18px;
      left: 20px;
    }
  }

  .learn__more {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--color-black-007);
    padding-top: 23px;
    padding-bottom: 26px;
    margin-top: 20px;
    text-decoration: none;
    color: var(--color-black);
    @include transition-base(all);

    :deep(.icon path) {
      fill: var(--color-black-04);
      @include transition-base(all);
    }

    &:hover {
      color: var(--color-primary);

      :deep(.icon path) {
        fill: var(--color-primary);
      }
    }
  }
}

.background-overlay {
  @include mq('mobile') {
    position: absolute;
    z-index: 1;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-black-02);
  }
}
</style>
