<template>
  <div class="card">
    <div class="pic">
      <AppIcon name="success-paid" size="60px" is-img-tag />
    </div>
    <div class="base__amount">
      <AppText size="21px" class="font-medium">
        <TooltipCopy is-inlined is-icon-hidden :text="paymentData?.baseAmount">
          <AppText size="21px" class="font-medium">
            {{ baseCurrencySymbol }}<NumberFormatter
              size="21px"
              class="font-medium"
              :value="paymentData?.baseAmount"
              :format="paymentData?.merchant?.settings?.numberFormat"
              :is-inr="props.paymentData?.baseCurrency === 'INR'"
            />
          </AppText>
        </TooltipCopy> {{ t('payment.label.paid') }}
      </AppText>
    </div>
    <div class="amount">
      <TooltipCopy is-icon-hidden :text="paymentData.expectedAmount">
        <AppText color="var(--color-black-05)">
          <NumberFormatter :value="paymentData?.expectedAmount" :format="paymentData?.merchant?.settings?.numberFormat" :is-inr="props.paymentData?.baseCurrency === 'INR'" /> <span class="strict-color-05">{{ exchangeCurrency }}</span>
        </AppText>
      </TooltipCopy>
      <AppTooltip
        v-if="paymentData.slippage?.isApplied"
        width="264px"
        text-align="left"
        style="margin-left: 6px;"
      >
        <template #text>
          {{ t('payment.complete.slippageIncluded') }}
        </template>
        <AppIcon name="info-circle-medium" size="18px" :opacity="0.4" is-img-tag />
      </AppTooltip>
    </div>
    <div class="company">
      <AppText
        size="14px"
        class="font-medium"
      >
        {{ t('payment.complete.recieptFrom') }} {{ paymentData.companyName }}
      </AppText>
    </div>
    <div class="info">
      <div class="block rate">
        <AppText opacity="0.5" class="d-flex align-items-center">
          {{ t('payment.complete.rate') }}
        </AppText>
        <div>
          <TooltipCopy is-icon-hidden :text="paymentData.marketRate">
            <AppText>
              {{ baseCurrencySymbol }}<NumberFormatter :value="paymentData?.marketRate" :format="paymentData?.merchant?.settings?.numberFormat" :is-inr="props.paymentData?.baseCurrency === 'INR'" />
            </AppText>
          </TooltipCopy>
        </div>
      </div>
      <div class="block fees">
        <div class="d-flex align-items-center">
          <AppText opacity="0.5">
            {{ t('payment.complete.fees') }}
          </AppText>

          <AppTooltip
            v-if="!selectedCoin.isPrimary"
            width="190px"
            text-align="left"
            style="margin-left: 6px;"
            cursor="default"
          >
            <template #text>
              {{ t('payment.label.thirdPartyTokenFee') }}
            </template>
            <AppIcon name="info-circle-medium" size="18px" :opacity="0.4" is-img-tag />
          </AppTooltip>
        </div>
        <div>
          <TooltipCopy is-icon-hidden :text="paymentData.currencyFees">
            <AppText>
              {{ baseCurrencySymbol }}<NumberFormatter :value="paymentData?.currencyFees" :format="paymentData?.merchant?.settings?.numberFormat" :is-inr="props.paymentData?.baseCurrency === 'INR'" />
            </AppText>
          </TooltipCopy>
        </div>
      </div>
      <div v-for="(transaction, index) in paymentData.transactions" :key="index" class="block hash">
        <AppText opacity="0.5">
          {{ t('payment.complete.hash') }}<span v-if="paymentData.transactions.length > 1"> (#{{ index + 1 }})</span>
        </AppText>
        <TooltipCopy is-icon-hidden>
          <AppText variant="div">
            {{ transaction.hash }}
          </AppText>
        </TooltipCopy>
      </div>
    </div>
    <div class="docs">
      <ExternalLink
        v-for="(transaction, index) in paymentData.transactions"
        :key="index"
        hover-color="var(--color-black)"
        :href="getBlockchainLink(transaction.hash)"
      >
        <template #prepend>
          <AppIcon class="prepend-icon" name="view-grid" size="10px" />
        </template>

        {{ t('payment.complete.transaction', { number: paymentData.transactions.length > 1 ? `#${index + 1}` : '' }) }}
      </ExternalLink>

      <AppTooltip
        :is-enabled="!isDownloadReceiptAvailable"
        cursor="default"
        @click.stop="() => {}"
      >
        <template #text>
          {{ t('payment.complete.notAvailable') }}
        </template>
        <ExternalLink
          hover-color="var(--color-black)"
          is-block
          :is-disabled="!isDownloadReceiptAvailable"
          :has-append="false"
          @click.stop="onLoadReceipt"
        >
          <template #prepend>
            <AppIcon class="prepend-icon" name="doc-bill-invoice" size="10px" />
          </template>

          {{ t('payment.complete.downloadReceipt') }}
        </ExternalLink>
      </AppTooltip>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import ExternalLink from '@/components/ExternalLink.vue';
import AppTooltip from '@/components/AppTooltip.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import NumberFormatter from '@/components/Helpers/NumberFormatter.vue';

import { useFetch } from '@/api';
import { useLang } from '@/composables/useLang';
import { downloadAsFile } from '@/utils/functions';
import { currencyList } from '@/common/data';

const props = defineProps({
  paymentData: {
    type: Object,
    required: true,
  },
  selectedCoin: {
    type: Object,
    default: () => {},
  },
  exchangeCurrency: {
    type: String,
    default: '',
  },
});

const { language } = useLang();

const coinName = computed(() => {
  if (props.selectedCoin.family === 'ERC-20') {
    return 'ethereum';
  }
  if (props.selectedCoin.family === 'TRC-20') {
    return 'tron';
  }
  if (props.selectedCoin.family === 'BEP-20') {
    return 'bnb';
  }

  return props.selectedCoin?.name?.toLowerCase();
});

const getBlockchainLink = (hash) => `${process.env.VUE_APP_BLOCKCHAIN_INFO_URL}/${coinName.value}/transaction/${hash}`;

// eslint-disable-next-line no-underscore-dangle
const paymentId = computed(() => props.paymentData._id);

const isDownloadReceiptAvailable = computed(() => props.paymentData?.state !== 'COMPLETE_MANUAL');

const onLoadReceipt = async () => {
  if (isDownloadReceiptAvailable.value) {
    const { axiosWrapper } = useFetch();
    const response = await axiosWrapper({
      type: 'get',
      url: `/merchants/invoices/payments/${paymentId.value}?lang=${language.value}`,
      responseType: 'blob',
    });

    if (response.result?.data) {
      downloadAsFile({
        data: response.result.data,
        filename: `${props.paymentData.receiptUUID}`,
        type: 'pdf',
      });
    }
  }
};

const baseCurrencySymbol = computed(() => currencyList.find((item) => item.short === props.paymentData?.baseCurrency)?.symbol);
</script>

<style lang="scss" scoped>
.card {
  .pic {
    padding-top: 40px;
    display: flex;
    justify-content: center;
  }

  .base__amount {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .amount {
    padding-top: 10px;
    display: flex;
    justify-content: center;
  }

  .company {
    margin-top: 25px;
    background: var(--color-F7F7F7);
    padding: 10px 40px;
  }

  .info {
    padding: 30px 40px 0 40px;
    display: flex;
    flex-wrap: wrap;

    .block {
      display: flex;
      flex-direction: column;

      & > :first-child {
        margin-bottom: 6px;
      }
    }

    .rate, .fees {
      width: 50%;
    }

    .hash {
      width: 100%;
      word-break: break-all;
      padding-top: 20px;
    }
  }

  .docs {
    margin-top: 44px;
    border-top: 1px solid var(--color-black-005);
    padding: 24px 30px 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;

    .prepend-icon {
      :deep(path) {
        fill: var(--color-black-04);
      }
    }

    :deep(.external-link:hover) {
      path {
        fill: var(--color-black);
      }
    }
  }

  :deep(.external-link--prepend) {
    margin-right: 12px;
  }
}
</style>
