<template>
  <div class="payment-progress">
    <Teleport to="#payment-header">
      <transition name="show">
        <AppSpinner :title="t('status.WAITING')" />
      </transition>
    </Teleport>
    <Transition name="show">
      <PaymentTimer
        v-show="!isHelpOpen"
        v-if="paymentData.activatedAt"
        :payment-status="paymentStatus"
        :state="paymentData.state"
        :activated-at="paymentData.activatedAt"
      />
    </Transition>

    <template v-if="!isHelpOpen">
      <TopButton
        :key="isHelpOpen"
        :text="t('common.help')"
        icon-name="info-question-circle"
        @click="isHelpOpen = true"
      />

      <div class="content">
        <div class="payment-header">
          <TooltipCopy :text="trimmedAmount?.toString()" is-icon-hidden :is-enabled="isTooltipVisible">
            <div class="amount">
              <AppText size="21px" class="font-medium">
                {{ trimmedAmount }}
              </AppText>
              <AppText
                v-if="paymentData.exchangeCurrency"
                size="21px"
                ml="6px"
                class="font-medium strict-color"
              >
                {{ exchangeCurrency }}
              </AppText>
              <AppText
                v-if="paymentStatus === 'UNDERPAID'"
                size="21px"
                :opacity="0.4"
                ml="6px"
                class="font-medium strict-color"
              >
                {{ t('common.remaining') }}
              </AppText>
              <AppTooltip
                v-if="paymentData.slippage?.isApplied"
                width="264px"
                text-align="left"
                style="margin-left: 6px; min-width: 18px;"
                cursor="default"
                @on-visible-change="isTooltipVisible = !isTooltipVisible"
                @click.stop="() => {}"
              >
                <template #text>
                  {{ t('payment.label.slippage') }}
                </template>
                <AppIcon name="info-circle-medium" size="18px" :opacity="0.4" is-img-tag />
              </AppTooltip>

              <AppTooltip
                v-if="isNetworkFeeHigh"
                text-align="left"
                width="264px"
                style="margin-left: 6px; min-width: 20px;"
                cursor="default"
                @on-visible-change="isTooltipVisible = !isTooltipVisible"
                @click.stop="() => {}"
              >
                <template #text>
                  {{ t('payment.label.highFeeNetworkTooltip') }}
                </template>
                <AppIcon name="fire" size="20px" :opacity="0.4" is-img-tag />
              </AppTooltip>
            </div>
          </TooltipCopy>

          <AppText
            v-if="!selectedCoin?.isPrimary && paymentStatus === 'WAITING' && exchangeCurrency && selectedCoin?.family"
            color="var(--color-black-04)"
            align="center"
            :currencies="paymentData.alternativeCurrencies"
            variant="div"
            :line-height="1.5"
            mb="14px"
          >
            {{ t('payment.label.otherTokenAlert', { coin: `${exchangeCurrency} (${selectedCoin?.family})` }) }}
          </AppText>

          <AppText
            v-if="paymentStatus === 'UNDERPAID'"
            variant="div"
            pt="4px"
            pb="12px"
            align="center"
            :opacity="0.5"
          >
            {{ t('payment.label.sent') }} {{ paymentData.paid }} {{ exchangeCurrency }}
          </AppText>
        </div>

        <div style="padding-top: 16px;">
          <Transition name="show">
            <QRcode
              v-if="qrCodeLink && paymentData.address"
              :data="qrCodeLink"
              :icon="selectedCoin?.icon"
              :family-icon="selectedCoin?.familyIcon"
            />
          </Transition>
        </div>

        <div style="padding-top: 30px;">
          <Transition name="show">
            <TooltipCopy v-if="paymentData.address" is-icon-hidden :text="paymentData.address">
              <AppText variant="div" class="wallet">
                <AppText class="font-medium strict-color">
                  {{ t('payment.label.toNetworkAddress', { networkName }) }}
                </AppText>
                <AppText class="coin-address">
                  {{ truncateLongString(paymentData.address) }}
                </AppText>
              </AppText>
            </TooltipCopy>
          </Transition>
        </div>
      </div>
    </template>

    <HelpText
      v-else
      step="progress"
      @close-help="isHelpOpen = false"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

import QRcode from '@/components/QRcode.vue';
import AppTooltip from '@/components/AppTooltip.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { paymentState } from '@/layouts/PaymentLayout/paymentState';
import { truncateLongString } from '@/utils/functions';
import TopButton from '@/views/client/ClientPayment/PaymentStatus/StatusCards/TopButton.vue';

import HelpText from '../../HelpText.vue';
import AppSpinner from '../AppSpinner.vue';

import PaymentTimer from './PaymentTimer.vue';

const props = defineProps({
  paymentData: {
    type: Object,
    default: () => {},
  },
  paymentStatus: {
    type: String,
    default: 'NEW',
  },
  selectedCoin: {
    type: Object,
    default: () => {},
  },
  exchangeCurrency: {
    type: String,
    default: '',
  },
});

const { disableAvatar } = paymentState();
disableAvatar();

const processedAmount = computed(() => props.paymentData.expectedAmount);
const amount = computed(() => (props.paymentStatus !== 'UNDERPAID' ? processedAmount.value : props.paymentData.missingAmount));
const trimmedAmount = computed(() => (amount.value ? Number(amount.value?.toFixed(5)) : ''));

const isHelpOpen = ref(false);

const qrCodeLink = computed(() => {
  if (props.selectedCoin?.family === 'ERC-20') {
    return `ethereum:${props.paymentData.address}`;
  }

  if (props.paymentData?.exchangeCurrency === 'XMR') {
    return `${props.selectedCoin?.name}:${props.paymentData.address}?tx_amount=${trimmedAmount.value}`;
  }

  return `${props.selectedCoin?.name?.toLowerCase()}:${props.paymentData.address}?amount=${trimmedAmount.value}`;
});

const isTooltipVisible = ref(true);

const networkName = computed(() => {
  if (props.selectedCoin?.family === 'ERC-20') {
    return 'ETH';
  }
  if (props.selectedCoin?.family === 'TRC-20') {
    return 'TRX';
  }
  if (props.selectedCoin?.family === 'BEP-20') {
    return 'BSC';
  }

  return props.exchangeCurrency;
});

const isNetworkFeeHigh = computed(() => ['ETH', 'TRX'].includes(networkName.value));
</script>

<style lang="scss" scoped>
:deep(.top__button) {
  position: absolute;
  top: 6px;
  left: 10px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 30px;

  .amount {
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 4px;

    &:hover {
      background: var(--color-F7F7F7);
    }
  }

  .wallet {
    max-width: 80vw;
    margin: 0 auto;
    padding: 10px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;

    &:hover {
      background: #F7F7F7;
    }

    & > :first-child {
      margin-bottom: 8px;
    }
  }
}

.payment-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 18px 66px 0;
}

.coin-address {
  width: 100%;
  word-wrap: break-word;
  text-align: center;
}
</style>
